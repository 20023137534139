import axios from 'axios'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { auth0Roles, strapiURL } from '../../../config'
import { formatPrice, permissionsHandler } from '../../../functions'
import { navigate } from 'gatsby'
import { StepCheckIcon } from '../../icons'
import { useSelector } from 'react-redux'
import globalData from '../../../../globalData.json'
import cookie from 'react-cookies'
import useGMT from '../../../hooks/useGTM'

const MemberSubscriptions = ({ user, userRole, userProfile }) => {
  const [step, setStep] = useState(1)
  const [listingPrices, setListingPrices] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  let memberSubscription =
    globalData?.CandidateCatalog?.prices?.filter(
      item => !item.isDiscontinued
    ) || []
  const navbarItems = [
    {
      label: 'Select Plan',
      id: 1,
    },
    {
      label: 'Make payment',
      id: 4,
    },
  ]

  const {
    pushCandidateAddToCartEvent,
    pushCandidateViewItemEvent,
    pushCandidateCheckoutEvent,
    pushCandidateSelectItemEvent,
  } = useGMT()

  useEffect(() => {
    handleCheckSubscription()
    pushCandidateViewItemEvent()
  }, [])

  useEffect(() => {
    if (subscription) {
      pushCandidateSelectItemEvent(subscription.price?.priceId)
    }
  }, [subscription])

  const handleCheckSubscription = async () => {
    if (
      (!user && userRole !== auth0Roles.Member.id) ||
      products?.subscriptionPurchased
    ) {
      navigate('/')
    }
    setListingPrices(memberSubscription)

    const result = await axios.post(
      strapiURL + '/auth0/user/checkAndCreateActiveSubscription'
    )

    if (result?.data?.isSubscriptionExist) {
      alert('Great News! We found your active subscription.')
      navigate('/member-dashboard')
    } else {
      setLoading(false)
    }
  }

  const userCheckhandler = () => {
    if (!subscription) {
      message.error('Please select a plan to continue')
      return
    }

    if (step === 1 && subscription) {
      pushCandidateAddToCartEvent(subscription.price?.priceId)
      pushCandidateCheckoutEvent(subscription.price?.priceId)
    }

    setSubmitLoading(true)
    localStorage.setItem('can-subscription', subscription.price?.priceId)
    axios
      .post(strapiURL + '/stripe/session/checkout', {
        line_items: [{ price: subscription.price.priceId, quantity: 1 }],
        success_url:
          window.location.origin +
          '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}',
        cancel_url: window.location.origin + '/account-candidate',
        customerEmail: userProfile?.username || userProfile?.user?.email || '',
        mode: 'subscription',
        customer: user?.customer_id || '',
        clientId: cookie.load('_ga')?.substring(6),
        sessionId: cookie
          .load(
            process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID?.replace(
              'G-',
              '_ga_'
            )
          )
          ?.split('.')[2],
      })
      .then(res => {
        window.location.href = res?.data?.url
      })
      .catch(err => {
        message.error('Something wrong while checkout.')
        setSubmitLoading(false)
      })
  }

  return (
    <>
      <div className="container mx-auto py-12">
        <div className="px-5 lg:px-0 w-full lg:w-4/5 mx-auto">
          <div className="w-full lg:w-3/5">
            {/* navbar Area */}
            {step > 0 && (
              <div className="navbar-area">
                <div className="flex items-center relative pb-6">
                  {step > 0 ? (
                    <Link to="/profile">
                      <img
                        src="/icons/icon-back-red.svg"
                        className="w-4 mr-2 cursor-pointer"
                      />
                    </Link>
                  ) : (
                    <div />
                  )}
                  <h1 className="text-lg font-sans font-semibold text-merlot mb-0">
                    Post jobs with Medreps
                  </h1>
                </div>
                <div className="flex items-center">
                  {navbarItems?.map((navItem, navIndex) => {
                    return (
                      <>
                        <div className="membership-step" key={navIndex}>
                          {step >= navItem.id ? (
                            <div className="active h-6 w-6 bg-[#059913] rounded-full flex items-center justify-center">
                              {step === navItem.id ? (
                                <div className="h-2 w-2 bg-white rounded-full" />
                              ) : (
                                <StepCheckIcon />
                              )}
                            </div>
                          ) : (
                            <div className="inactive bg-border h-6 w-6 bg-[#E1E1E1] rounded-full" />
                          )}

                          <div className="absolute">
                            <p className="w-full text-xs font-semibold font-sans w-max mt-4">
                              {navItem.label}
                            </p>
                          </div>
                        </div>
                        {navIndex !== navbarItems?.length - 1 && (
                          <div className="divider w-85 h-px bg-border" />
                        )}
                      </>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          {/* navbar Area */}
          {loading ? (
            <div
              className="w-3/5 min-h-700px bg-cover bg-center bg-no-repeat mt-16"
              style={{
                backgroundImage: `url(/loaders/employer-products-loader.png)`,
              }}
            />
          ) : (
            <div className="flex">
              <div className="w-full lg:w-3/5">
                {/* Steps Area */}
                {/* Step 1 */}
                {step === 1 && (
                  <div className="pt-16 w-full lg:w-11/12">
                    <h1 className="font-playfair font-normal text-headingThree mb-2">
                      Choose your subscription plan
                    </h1>
                    <p className="text-sm font-sans">
                      We think it’s a good idea to stay plugged into the job
                      market, so all MedReps memberships automatically renew.
                      You may cancel at any time.
                    </p>
                    <div className="options-div">
                      {listingPrices?.map((price, priceIndex) => {
                        let isSelected =
                          subscription?.price?.id === price?.price?.id

                        return (
                          <div
                            onClick={() => setSubscription(price)}
                            key={priceIndex}
                            className={`mb-8 single-option bg-white shadow-md flex justify-between items-center p-4 rounded-md cursor-pointer border-2 border-transparent ${
                              isSelected
                                ? 'border-2 border-merlot active-card-shadow'
                                : ''
                            }`}
                          >
                            <div className="flex items-center">
                              {isSelected ? (
                                <div className="flex items-center justify-center active bg-merlot h-9 w-9 bg-merlot rounded-full flex items-center justify-center">
                                  <img
                                    src="/icons/check-mark.svg"
                                    className="mb-0"
                                  />
                                </div>
                              ) : (
                                <div className="inactive bg-border h-9 w-9 bg-[#E1E1E1] rounded-full" />
                              )}

                              <div className="ml-4">
                                <p className="font-sans font-bold text-lg mb-0">
                                  {price?.price?.nickname || price?.name}
                                </p>
                                <p className="font-sans text-xs mb-1">
                                  {price.description}
                                  <span className="text-[#c40000]">
                                    {price?.price?.description}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                className={`${
                                  isSelected ? 'text-merlot' : 'text-black'
                                } font-sans font-bold text-lg mb-0`}
                              >
                                <span>
                                  {' '}
                                  {formatPrice(price?.price?.amount, 'USD')}
                                </span>
                              </p>
                              <p
                                className={`${
                                  isSelected ? 'text-merlot' : 'text-black'
                                } font-sans font-bold text-lg mb-0`}
                              >
                                <span>{price?.price?.duration}</span>
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <button
                      onClick={() => {
                        userCheckhandler()
                      }}
                      className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
                    >
                      {submitLoading ? 'Loading....' : 'Pay'}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default MemberSubscriptions
